.Coming-img{
    border-radius: 50%;
    margin-top: 40px;
    animation: rotateImage 8s linear infinite;
    width: auto;
    /* max-width: 500px; */
}

@keyframes rotateImage {
    from {
      transform: rotate(0deg); 
    }
    to {
      transform: rotate(360deg);
    }
  }