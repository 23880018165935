@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap);
.account__modal {
  border: none !important;
  box-shadow: 0px 0px 20px #FEBF32 !important;
  border-radius: 10px !important;
  height: auto !important;
  background: linear-gradient(100deg, #17171A 20%, #0E0E10) !important;
  color: #fff !important;
  width: 600px;
}

.account__modal hr {
  margin-bottom: 20px !important;
  margin-top: 20px !important;
}

.account__modal_header {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  color: #FEBF32 !important;
}

.account__modal_header span {
  cursor: pointer !important;
}

.account__modal_details {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.account__modal_details button {
  justify-content: space-evenly;
  background: transparent;
  border: none;
  font-size: 14px;
  font-weight: 600;
}

.view_on_scan,
.account_logout {
  display: flex;
  align-items: center;
  margin-top: 25px;
}

.view_on_scan span,
.account_logout span {
  margin-left: 8px;
}

.view_on_scan h2,
.account_logout h2,
.view_on_scan span,
.account_logout span {
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer !important;
}

.view_on_scan:hover > h2,
.account_logout:hover > h2,
.view_on_scan:hover > span,
.account_logout:hover > span {
  color: #fff;
}

.account_logout {
  cursor: pointer !important;
}

.account_logout:hover {
  background: #FEBF32;
  border: 1px solid transparent !important;
}

.account_logout {
  border: 1px solid rgba(255, 255, 255, 0.12) !important;
  padding: 8px 20px;
  border-radius: 20px;
}

.wallet_info {
  display: flex;
  align-items: center;
  margin-right: -5px;
}

.account__modalContainer {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

#simple-modal-description {
  line-height: 40px;
}

@media (max-height: 800px) and (max-width: 415px) {
  #simple-modal-description {
    line-height: 25px;
  }
}

@media (max-height: 930px) and (max-width: 500px) {
  #simple-modal-description {
    line-height: 25px;
  }
}

@media (max-height: 800px) and (max-width: 415px) {
  .account__modal {
    width: 300px;
  }
}

@media (max-height: 930px) and (max-width: 500px) {
  .account__modal {
    width: 330px;
  }
}

/* Connect to a wallet h2 */
.sc-eCssSg.sc-jSgupP.sc-dOSReg.jnvqfy.cjuMmG.dKsJwV h2,
h2[color='text'] {
  color: #FEBF32 !important;
}
.kVQRvg,
.cjVljT{
  background:#17171A !important;
  box-shadow: 0px 20px 36px -8px #FEBF32, 0px 1px 1px rgba(0,0,0,0.05) !important;
  border: 1px solid #FEBF32 !important;

}
/* 'x' and Wallet names on Connect to a Wallet */
button[id='wallet-connect-metamask'] div,
button[id='wallet-connect-trustwallet'] div,
button[id='wallet-connect-mathwallet'] div,
button[id='wallet-connect-tokenpocket'] div,
button[id='wallet-connect-walletconnect'] div,
button[id='wallet-connect-binance chain wallet'] div,
button[id='wallet-connect-safepal wallet'] div,
.fKQcGp {
  color: #FEBF32 !important;
  fill: #FEBF32 !important;
} 
/* button[id="wallet-connect-binance chain wallet"] {
  display: none;
} */
.bNvqXz,
.fDeZGR,
.iubMiB{
  margin-top: 10px !important;
  background-color: #17171A !important;
  border: 1px solid #FEBF32 !important;
}
.jJOCbM{
  border-bottom: 1px solid #FEBF32 !important;
}
.fcyZwL{
  margin-top: 10px !important;
  background-color: #17171A !important;
  border: 1px solid #FEBF32 !important;
}
/* 'Learn how to connect' on Connect to a Wallet */
.sc-gsTCUz.sc-bYEvPH.jukfzI.bAxdNz.sc-lcujXC.keprIM,
.sc-gsTCUz.sc-bYEvPH.jukfzI.bAxdNz.sc-lcujXC.keprIM svg, 
a[href="https://docs.pancakeswap.finance/guides/faq#how-do-i-set-up-my-wallet-on-binance-smart-chain"] {
  color: #FEBF32;
  fill: #FEBF32;
  display: none;
}

/* Dark background on Wallet Connect page */
.sc-bBrOnJ.hUVHli,
div[role='presentation'] {
  background: rgba(0, 0, 0, 0.603);
}

/* Pricing */
.sc-tYoTV.kRwMwX {
  /* border: 1px solid red; */
  margin-top: -5px;
  padding: 10px;
}

/* Swap Button */
.sc-gHftXq.bCCDuy {
  margin-top: 0px;
  padding-top: 5px;
  margin-bottom: -30px;
}

/* From and To text */
.sc-gsTCUz.jcQTaw {
  color: #abafc4;
  font-weight: 600;
}

.sokuswap__logo {
  object-fit: contain;
  height: 110px;
  margin-top: -25px;
}

@media (max-height: 800px) and (max-width: 415px) {
  .sokuswap__logo {
    height: 100px;
  }
}

.sokuswap__logo {
  text-align: center;
  padding: 20px 0px;
}

.sokuswap__toggleContainer {
  display: flex;
  justify-content: center;
  margin-top: -5px;
  padding-bottom: 10px;
  align-items: center;
}

.sokuswap__toggleContainer p {
  padding: 0px 20px;
  color: #fff;
  font-size: 14px;
  font-weight: bolder;
  margin-bottom: 8px;
}

.toggleActive {
  text-shadow: 0px 0px 20px #FEBF32, 1px 1px 10px #FEBF32;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 58px;
  height: 20px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 18px;
  width: 18px;
  left: 7px;
  bottom: 1px;
  background-color: #FEBF32;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #fff;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
/*# sourceMappingURL=SokuSwap.css.map */

.currencyText,
.modal_text {
  color: #FEBF32 !important;
  fill: #FEBF32 !important;
}

#button-tooltip {
  background-color: #fff;
  display: flex;
  justify-content: center;
  margin-right: 10px;
  padding: 20px;
  font-size: 12px;
  color: #FEBF32;
  border: 1px solid rgba(5, 25, 90, 0.125);
  border-radius: 10px;
  width: 175px;
  z-index: 9999;
}

.helper {
  cursor: help;
  font-size: 16px;
  color: #FEBF32;
  border: none;
  background-color: transparent;
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #c9c9c9 !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #c9c9c9 !important;
  opacity: 1; /* Firefox */
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #c9c9c9 !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #c9c9c9 !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #c9c9c9 !important;
}

.maxButton {
  color: #FEBF32 !important;
  font-size: 10px !important;
  margin-right: -10px;
  display: none !important;
}

#pair {
  color: #fff;
  font-size: 12px;
  font-weight: 600;
}

.currencySelector svg {
  /* border: 1px solid red !important; */
  margin-right: 10px;
  fill: #fff;
}

.token-amount-input {
  font-size: 12px;
  /* border: 1px solid red !important; */
}

/* .inputPanel {
    border: 1px solid red;
    width: 100%;
    margin-right: 70px;
    padding-right: 10px;
  } */

.sokuswap__fromAmount,
.sokuswap__fromAmount input:focus,
.sokuswap__toAmount input,
.sokuswap__toAmount input:focus {
  font-family: 'Roboto Mono', monospace;
  border: none;
  outline: none;
  font-size: 20px !important;
  padding: 15px 0px !important;
  width: 60% !important;
  color : #FEBF32 !important;
  /* border: 1px solid red !important; */
}

@media (max-height: 720px) {
  .sokuswap__fromAmount,
  .sokuswap__fromAmount input:focus,
  .sokuswap__toAmount input,
  .sokuswap__toAmount input:focus {
    font-size: 14px !important;
  }
}

.sokuswap__swapButton {
  background-color: #FEBF32;
  border: none;
  padding: 10px;
  border-radius: 25px;
  color: #fff;
  display: flex;
  width: 100%;
  justify-content: center;
  font-weight: 550;
  cursor: pointer;
  height: 48px !important;
  font-size: 16px !important;
}

.sokuswap__swapButton:hover,
.sokuswap__swapButton button:hover {
  background-color: #3acaff;
}

.sokuswap__swapButton button {
  background: transparent;
  cursor: pointer;
  color: #fff;
}
/*# sourceMappingURL=ConnectWalletButton.css.map */

.sokuswap__heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
}

@media (max-height: 800px) and (max-width: 415px) {
  .sokuswap__heading {
    padding-bottom: 12px;
  }
}

.sokuswap__heading h2 {
  font-size: 20px;
  color: #FEBF32;
}

@media (max-height: 800px) and (max-width: 415px) {
  .sokuswap__heading h2 {
    font-size: 16px;
  }
}

.sokuswap__heading span {
  font-size: 25px;
  color: #d8d8d8;
  cursor: pointer;
}

@media (max-height: 800px) and (max-width: 415px) {
  .sokuswap__heading span {
    font-size: 20px;
  }
}

.sokuswap__heading button {
  background: transparent;
  border: none;
}

.sokuswap__heading span:hover {
  color: #b8b8b8;
}

.sc-gsTCUz.cUMPjy {
  color: #FEBF32;
}

.sc-dlfnbm.eZqaVE {
  background-color: #FEBF32;
  color: #fff;
}

.sc-dlfnbm.eZqaVE.sc-hKgILt.hFmlgK {
  background-color: transparent;
}

.sc-dlfnbm.eZqaVE.sc-hKgILt.hFmlgK svg {
  fill: #FEBF32;
}
/*# sourceMappingURL=header.css.map */
/* Import Pool Buttons */

.poolFinder__button {
  /* border: 1px solid red; */
  background-color: #2A2D3C !important;
}

@media (max-height: 930px) and (max-width: 650px) {
  .sc-BXqHe.johgUp {
    padding-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-height: 930px) and (max-width: 650px) {
  .sc-dQppl.eAOCdS.sc-hJJQhR.Ebjwy {
    width: auto;
    margin-left: 0;
    padding: 20px;
  }
}

.sc-exiMuG.jOJoLv .sc-kIeTtH.betTyM .sc-iumJyn.htMwph {
  margin: 0 !important;
  padding: 0 !important;
  width: 100% !important;
}

@media (max-height: 930px) and (max-width: 650px) {
  .sc-exiMuG.jOJoLv .sc-kIeTtH.betTyM .sc-iumJyn.htMwph {
    padding: 10px;
  }
}

.sc-exiMuG.jOJoLv .sc-kIeTtH.betTyM .sc-iumJyn.htMwph .sc-gGTGfU.guFDoz {
  padding: 15px;
}

.sc-hOqqkJ.sc-dacFzL.sc-fbNXWD.drKBTM.hZLFGM.fGJHEj .sc-httYMd.hEeDUj {
  display: flex;
  padding-right: 0 !important;
  margin-left: 20px;
}

.sc-kIeTtH.betTyM .sc-biOYSp.gUHGbn,
.sc-kIeTtH.edjgnz {
  max-width: auto;
}

@media (max-height: 930px) and (max-width: 650px) {
  .sc-kIeTtH.betTyM .sc-biOYSp.gUHGbn,
  .sc-kIeTtH.edjgnz {
    padding: 10px;
  }
}

@media (max-height: 930px) and (max-width: 650px) {
  div[font-size='64px'] {
    font-size: 24px;
  }
}

@media (max-height: 930px) and (max-width: 650px) {
  div[font-size='24px'] {
    font-size: 16px;
  }
}

@media (max-height: 930px) and (max-width: 650px) {
  .sc-tkKAw.jawwJq {
    font-size: 16px;
  }
}
/*# sourceMappingURL=removeLiquidity.css.map */
.navbar__items {
  display: flex !important;
  justify-content: center !important;
  list-style-type: none !important;
  color: rgba(255, 255, 255, 0.87) !important;
  align-items: center !important;
}

.navbar__items li {
  padding: 20px 15px;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 600;
  cursor: pointer;
  font-size: 17px;
  transition: color 0.1s, font-size 0.1s;
}

.navbar__options {
  padding-left: 20px;
  display: flex;
}

@media (max-height: 930px) and (max-width: 650px) {
  .navbar__items li {
    font-size: 14px;
  }
}

.navbar__items li:hover,
.active {
  color: #FEBF32;
  font-size: 17.05px;
}

@media (max-height: 930px) and (max-width: 650px) {
  .navbar__items li:hover,
  .active {
    font-size: 14.5px;
  }
}

.active {
  color: #FEBF32 !important;
}

.nav_link {
  text-decoration: none;
  color: #ABAFC4;
  cursor: pointer;
}

nav {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  text-align: center !important;
  padding: 20px !important;
  padding-bottom: 10px !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.125) !important;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.125) !important;
  width: 100vw;
}

@media (max-height: 930px) and (max-width: 650px) {
  nav {
    justify-content: space-around !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.125);
    padding: 10px !important;
  }
}

.nav_logo {
  object-fit: contain;
  height: 70px;
}

@media (max-height: 930px) and (max-width: 650px) {
  .nav_logo {
    display: none;
  }
}

.connectWallet__options__DESKTOP {
  display: flex;
  width: 35%;
  justify-content: flex-end;
}

@media (max-height: 930px) and (max-width: 650px) {
  .connectWallet__options__DESKTOP {
    display: none;
  }
}

@media (min-width: 600px) and (max-width: 900px) {
  .connectWallet__options__DESKTOP {
    display: none;
  }
}

.connectWallet__options__DESKTOP li {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-left: 10px;
  font-size: 16px;
}

.connectWallet__nav {
  font-family: 'Poppins', 'sans-serif';
  background: transparent;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 8px 10px;
  padding-right: 20px;
  border-radius: 12px;
  /* -webkit-box-flex: 0.5;
  -ms-flex: 0.5;
  flex: 0.5; */
  color: rgba(255, 255, 255, 0.87);
  margin-right: -20px;
  transition: color 0.1s, font-size 0.1s;
}

/* @media (min-width: 653px) and (min-height: 730px) {
  .connectWallet__nav {
    flex: 1;
  }
} */

.connectWallet__nav button {
  padding: 0px 15px;
  font-family: 'Poppins', sans-serif;
  color: rgba(255, 255, 255, 0.87);
  cursor: pointer;
  background: transparent;
  border: none;
  font-size: 16px;
  transition: color 0.1s, font-size 0.1s;
}

.connectWallet__nav button:hover {
  font-size: 16.5px;
  /* font-weight: 550; */
  color: #fff;
}

/* .claimSoku__nav {
  font-family: 'Poppins', 'sans-serif';

  background: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 8px 10px;
  font-size: 1vw !important;
  border-radius: 25px;
  border: 1px solid rgba(255, 255, 255, 0.12);
  -webkit-box-flex: 0.5;
  -ms-flex: 0.5;
  flex: 0.55;
  color: #fff;
} */

/* .claimSoku__nav {
  flex: 0.55;
}

.claimSoku__nav:hover {
  background-color: #FEBF32;
  border: 1px solid #FEBF32;
  color: #fff;
} */

.connectWallet__options__DESKTOP li button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 10px;
  border-radius: 12px;
  color: #222531;
  border: none;
  background: #FEBF32;
  cursor: pointer;
  font-weight: 600;
}

.connectWallet__options__DESKTOP li button:hover {
  color: #fff;
}

.account {
  background: #FEBF32;
  border-radius: 10px;
  /* color: rgba(255, 255, 255, 0.8); */
  cursor: pointer;
  padding: 4px 10px;
  flex: 0.3 1;
  font-weight: bold;
  font-size: 14px !important;
}

.account:hover {
  color: #fff;
}

.sokuswap__navbar #hidden_navLinks {
  display: none;
}

.sokuswap__navbar #open {
  position: absolute;
  right: 1%;
  top: 0;
  margin-top: 120px;
  background-color: #FEBF32;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.12);
  transition: all 1s ease-in-out;
  display: block;
}

.sokuswap__navbar .hidden_navLinks li a {
  cursor: pointer;
  z-index: 99;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  background-color: transparent;
  border: none;
}

.sokuswap__navbar .hidden_navLinks li {
  list-style-type: none;
  display: flex;
  padding: 20px 25px;
}

.sokuswap__navbar .hidden_navLinks li p {
  padding-left: 10px;
  color: rgba(255, 255, 255, 0.87);
  cursor: pointer !important;
  font-weight: 600;
}

.hidden_navLink a {
  color: rgba(255, 255, 255, 0.87) !important;
}

.hidden_navLink a:hover {
  color: #fff !important;
}

.disabled_link {
  cursor: not-allowed !important;
  pointer-events: none;
}

.disabled_link p,
.disabled_link span {
  color: rgba(255, 255, 255, 0.4) !important;
}

/* .hidden_navLink a {
  border: 1px solid red !important;
} */
/*# sourceMappingURL=Menu.css.map */

.Coming-img{
    border-radius: 50%;
    margin-top: 40px;
    animation: rotateImage 8s linear infinite;
    width: auto;
    /* max-width: 500px; */
}

@keyframes rotateImage {
    from {
      transform: rotate(0deg); 
    }
    to {
      transform: rotate(360deg);
    }
  }
.connectWallet__options__MOBILE ul {
  list-style: none;
  color: #fff;
  display: flex;
  justify-content: space-evenly;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px;
  background: #17171A;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  z-index: 99999; 
}

@media (min-width: 901px) {
  .connectWallet__options__MOBILE ul {
    display: none;
  }
}

.connectWallet__options__MOBILE li {
  cursor: pointer;
  display: flex;
  align-items: center;
  text-align: center;
}

.connectWallet {
  background: #FEBF32;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 10px;
  font-size: 12px;
  border-radius: 12px;
  flex: 0.5 1;
}

.connectWallet button {
  background-color: #FEBF32;
  color: #fff;
  border: none;
  width: 100%;
}

.claimSoku {
  background: #FEBF32;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 10px;
  font-size: 12px;
  border-radius: 12px;
  flex: 0.4 1;
}

.connectWallet__options__MOBILE li span {
  background: #eeeeee;
  color: #FEBF32;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 10px;
  border-radius: 12px;
}

.account__footer {
  background: #FEBF32 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 10px;
  font-size: 12px;
  border-radius: 12px;
  flex: 0.5 1;
  color: #fff;
  font-weight: 300;
  cursor: none;
}

.connectWallet__options__MOBILE #hidden_navLinks {
  display: none;
}

.connectWallet__options__MOBILE #open {
  position: fixed;
  left: 62.5vw;
  bottom: 20vw;
  background-color: #eeeeee;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.12);
  transition: all 1s ease-in-out;
  display: block;
  width: 150px;
}

@media (min-width: 901px) {
  .connectWallet__options__MOBILE #open {
    display: none;
  }
}

.connectWallet__options__MOBILE .hidden_navLinksMobile li a {
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  background-color: transparent;
  border: none;
}

.connectWallet__options__MOBILE .hidden_navLinksMobile li {
  list-style-type: none;
  display: flex;
  padding: 3px;
}

.connectWallet__options__MOBILE .hidden_navLinksMobile li p {
  padding-left: 3px;
  color: #FEBF32;
  cursor: pointer !important;
  font-weight: 600;
  font-size: 12px;
}

.connectWallet__options__MOBILE .hidden_navLinksMobile li .material-icons {
  color: #FEBF32;
  background-color: transparent;
}

.connectWallet__options__MOBILE .hidden_navLinksMobile li p:hover,
.connectWallet__options__MOBILE .hidden_navLinksMobile li .material-icons:hover {
  color: #062072;
}

.connectWallet__options__MOBILE button {
  border-radius: 6px;
  border: none;
}
/*# sourceMappingURL=MobileFooter.css.map */
